import { Formatter, PercentagePipe } from '@mint-libs/common';
import { Component, OnInit, Inject } from '@angular/core';
import * as Ng1Services from '../../core/hybrid/ng1-upgraded-providers';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { MetricGridDataHelperService } from '../../shared/metric-grid/metric-grid-data-helper.service';
import { BaseDashboardComponent } from '../../shared/base/base-dashboard.component';
import { Code } from './../../app.constants';
import { UserProfileService } from '../../core/context/user-profile.service';
import { ChartType } from '../../shared/metric-chart/chartType.enum';
import { MessagesService } from '@mint-libs/context';
import { ContextState } from '@mint-libs/context';
import { selectConfigurationState } from '@mint-libs/configuration';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { LocalStorageService } from '@mint-libs/network';
import { PageTour } from '@pagetour/sdk';
import { OcvFloodgateService } from '../../ocv-feedback/ocv-floodgate.service';
import { environment } from '../../../environments/environment';
import { TooltipSize } from '../../shared/tooltip/tooltip-size.enum';
import { FiltersService } from '../../filters/filters.service';
import { ENVIRONMENT_CONFIG } from '@mint-libs/common';
import { LoggerService } from '@mint-libs/logging';
import { RoleFeatureService } from '@mint-libs/context';
import { HeaderComp } from 'ag-grid-community/dist/lib/headerRendering/header/headerComp';
import { NavigationService } from '../../core/navigation/navigation.service';
import { ImpersonationService } from 'src/app/core/impersonation/impersonation.service';
import { InitializationService } from 'src/app/core/initialization/initialization.service';
import { UserSessionService } from 'src/app/core/session/user-session-service';
import { selectSharedContextState, SharedContext } from '@mint-libs/common';

@Component({
  selector: 'mint-seller-dashboard',
  templateUrl: './seller-dashboard.component.html',
  styleUrls: ['./seller-dashboard.component.scss'],
  providers: [Code]
})
export class SellerDashboardComponent extends BaseDashboardComponent implements OnInit {
  gridView = false;
  commonFactory: any;
  isFSA: Boolean;
  chartType: ChartType;
  activeTabId = this.gridView ? 'dashboard_grid' : 'dashboard_chart';
  toggleEstimator = false;
  bucketEstimationTooltip: any;
  Enable_Bucket_Estimation: any;
  isChecked: Boolean;
  estimatorEligibleForQuarter = false;
  CurrentQuarter: any;
  isBucketEstimatorEnabled = false;
  isDMYEligible = false;
  envConfig: any;
  showBonusPayoutInfo: boolean;
  useNewPayoutAPI: boolean;
  payoutSummaryResult: any;
  isOptOut: boolean;

  chartGridViewTab = [
    {
      title: ` <span>
      <svg class="view__icon"  width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 9V5.53845H2.2V9H0ZM2.93333 9V2.76923H5.13333V9H2.93333ZM8.8 2.76923H11V9H8.8V2.76923ZM5.86667
      9V0H8.06667V9H5.86667Z"
          fill="#828282"
        />
      </svg>
    </span>
    Chart view`,
      id: 'dashboard_chart'
    },
    {
      title: `<span>
      <svg class="view__icon"  width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H11V3.46153H0V0ZM0 9V5.53845H11V9H0Z" fill="#828282" />
      </svg>
    </span>
    Grid view`,
      id: 'dashboard_grid'
    }
  ];

  constructor(
    private navigationService: NavigationService,
    public impersonationService: ImpersonationService,
    public rolefeatureService: RoleFeatureService,
    public initializationService: InitializationService,
    public filterService: FiltersService,
    private localStorage: LocalStorageService,
    private loggingService: LoggerService,
    private contextState: Store<ContextState>,
    formatter: Formatter,
    sanitizer: DomSanitizer,
    public router: Router,
    public sharedService: SharedService,
    private metricGridDataHelperService: MetricGridDataHelperService,
    percentagePipe: PercentagePipe,
    public messageService: MessagesService,
    public userProfileService: UserProfileService,
    public userSessionService: UserSessionService,
    public sharedContextState: Store<SharedContext>,
    floodgateService: OcvFloodgateService,
    @Inject(ENVIRONMENT_CONFIG) envConfig
  ) {
    super(
      filterService,
      sanitizer,
      percentagePipe,
      formatter,
      initializationService,
      impersonationService,
      router,
      messageService,
      userProfileService,
      floodgateService,
      userSessionService,
      sharedContextState
    );
    this.envConfig = envConfig;
    this.sharedService = sharedService;
    this.filterService = filterService;
    this.chartType = ChartType.Attainment;
    this.isFSA = this.filterService.isFSA();
    this.showBonusPayoutInfo = this.envConfig.showBonusPayoutInfo;
    this.useNewPayoutAPI = this.showBonusPayoutInfo && this.filterService.selectedFiscalYear >= 2022;

    this.sharedContextState.select(selectSharedContextState).subscribe(sharedContext => {
      this.sharedContext = sharedContext;
      this.isOptOut = this.sharedContext.optoutDetails.isOptOut;
      // On page feature change configure estimator
      if (sharedContext.Settings.UserRolePermissions) {
        this.rolefeatureService.pageFeatures = sharedContext.Settings.UserRolePermissions;
        this.pageFeatures = this.rolefeatureService.pageFeatures;
        if (this.latestFiscalYear) {
          this.configureEstimator(this.latestFiscalYear);
        }
      }
    });
    this.getConfigDetails();
  }

  pageFeatures = null;
  latestFiscalYear = null;

  ngOnInit() {
    this.isDMYEligible = this.sharedContext?.IsDMYEligible;
    this.loggingService.trackPageView(this.sharedContext.incentiveType + 'SellerDashboard', { UserAlias: this.sharedContext.alias });
    this.showContentLoaderTile = true;
    this.metricGridDataHelperService.getMetricTileData(true, this.useNewPayoutAPI, this.isOptOut).subscribe(([payLinesResult, payoutSummaryResult, targetSummary]) => {
      if (payLinesResult && payoutSummaryResult) {
        this.payoutSummaryResult = payoutSummaryResult;
        this.setMetricTiles(payoutSummaryResult, payLinesResult);
      }
      this.showContentLoaderTile = false;
    });
    if (environment.isPageTourEnabled) {
      // <---------------------------- auto play tour code start-------------------------------------->
      const pageTour = PageTour.GetInstance();
      const pageContext = pageTour.getPageContext();

      pageTour.autoPlayByContext(pageContext, 1000, false).then(
        () => {
          console.log('auto play started');
        },
        () => {
          console.log('auto play ended');
        }
      );

      // <---------------------------- auto play tour code end-------------------------------------->
    }
  }

  getConfigDetails() {
    this.contextState
      .pipe(
        select(selectConfigurationState),
        filter(val => val !== undefined && val.configs !== null)
      )
      .subscribe(state => {
        const latestFiscalYear = state.configs.latestFiscalYear;
        this.latestFiscalYear = latestFiscalYear;
        if (this.pageFeatures) {
          if (this.latestFiscalYear) {
            this.configureEstimator(latestFiscalYear);
          }
        }
      });
  }

  configureEstimator(latestFiscalYear) {
    const EstimatorFeatureId = this.rolefeatureService.features.Estimator_Bucket_Level;
    const EligiblePageFeatures = this.rolefeatureService.pageFeatures.filter(page => page.PageId === 1);
    const isEstimatorAvailable = EligiblePageFeatures[0]?.FeatureIds.indexOf(EstimatorFeatureId);
    if (isEstimatorAvailable !== -1) {
      this.isBucketEstimatorEnabled = true;
    }
    this.CurrentQuarter = this.getPartitionText();
    this.bucketEstimationTooltip = 'This is Bucket Estimation tooltip';
    this.Enable_Bucket_Estimation = 'Track_Bucket_Estimator';
    this.isChecked = false;
    // if(this.isDMYEligible){
    //   this.estimatorEligibleForQuarter= false;
    //   return;
    // }
    this.estimatorEligibleForQuarter =
      this.filterService.selectedFiscalQuarterId >= this.sharedContext.DefaultFilter.FiscalQuarterId && this.sharedContext.DefaultFilter.FiscalYear === latestFiscalYear;
  }

  getPartitionText() {
    const startDate = new Date(this.sharedContext?.DefaultFilter?.StandardTitle?.StartDate);
    const partitions = this.sharedContext?.DefaultFilter?.StandardTitle?.ApplicablePartitions?.filter(x => x.FiscalQuarterId === this.filterService.selectedFiscalQuarterId);
    const endDate = new Date(partitions[0]?.Duration?.EndDate);

    return this.filterService.selectedCodePeriod + ' (' + this.getShortMonth(startDate) + ' - ' + this.getShortMonth(endDate) + ')';
  }

  getShortMonth(date) {
    return date.toLocaleString('default', { month: 'short' });
  }

  playTour(event: any) {
    if (this.gridView === true) {
      this.gridView = false;
    }
  }

  onTabChange(event) {
    this.gridView = event.nextId === 'dashboard_grid' ? true : false;
  }

  viewReport(id) {
    this.sharedService.ngViewReport(id, id);
  }

  viewEstimator() {
    this.navigationService.navigateTo('estimator');
  }
  setMetricTiles(payoutSummary: any, allPayLines: any) {
    if (this.useNewPayoutAPI) {
      super.setYtdPayoutAndNextPayoutKPIFromNewPayoutSummaryAPI(payoutSummary);
    } else {
      super.setYtdPayoutAndNextPayoutKPI(payoutSummary);
    }
    super.setWeightedAttainmentKPI(allPayLines);
    if (this.userProfileService.getAwardConsideration() === 'GoldAndTopAttainment') {
      this.weightedAttainmentKPI.toolTipSize = TooltipSize.Large;
      this.weightedAttainmentKPI.toolTipText =
        '<p>Total Weighted Attainment is the sum of individual bucket attainment x weight. ' +
        "Total weighted attainment is not used in calculating payout or rewards and it doesn't include payout caps, threshold or determiners used to calculate year end awards. " +
        'This number is provided solely as an information data point. Your actual weighted attainment used for other purposes may be higher or lower.</p>';
      //PPI Plans does not have Gold and Platinum Club Awards
      if (!this.filterService.selectedStandardTitle.PlanName.includes(' PPI ')) {
        this.weightedAttainmentKPI.toolTipText +=
          '<h6>Platinum Club Award - Top Attainment</h6>' +
          '<p>Weighted attainment is used for Top Attainment Award criteria. ' +
          'An eligible seller must be at a minimum of 100% of total weighted attainment and at least 50% of buckets (per participation) must be above target. ' +
          'To review your weighted attainment for previous participation, you can select role summary in the global filter. Criteria can be subject to change. ' +
          "For more information on program and eligibility criteria, please visit <a href='https://microsoft.sharepoint.com/teams/wwrr/SitePages/PlatinumClub-TopAttainment.aspx' target='_blank'>Top Attainment</a>.</p>" +
          '<h6>Gold Club Award</h6>' +
          '<p>Weighted attainment is used for Gold Club criteria. An eligible seller must be at a minimum of 100% of total weighted attainment and at least 50% of buckets (per participation) must be above target. ' +
          'To review your weighted attainment for previous participation(s), you can select role summary in the global filter. ' +
          "Criteria can be subject to change. For more information about program and eligibility criteria, please visit <a href='https://microsoft.sharepoint.com/teams/wwrr/SitePages/GoldClub-Attainment.aspx' target='_blank'>Gold Club</a>.</p>";
      }
    } else if (this.userProfileService.getAwardConsideration() === 'TopAttainment') {
      this.weightedAttainmentKPI.toolTipSize = TooltipSize.Large;
      this.weightedAttainmentKPI.toolTipText =
        '<p>Total Weighted Attainment is the sum of individual bucket attainment x weight. ' +
        "Total weighted attainment is not used in calculating payout or rewards and it doesn't include payout caps, threshold or determiners used to calculate year end awards. " +
        'This number is provided solely as an information data point. Your actual weighted attainment used for other purposes may be higher or lower.</p>' +
        '<h6>Platinum Club Award - Top Attainment</h6>' +
        '<p>Weighted attainment is used for Top Attainment Award criteria. ' +
        'An eligible seller must be at a minimum of 100% of total weighted attainment and at least 50% of buckets (per participation) must be above target. ' +
        'To review your weighted attainment for previous participation, you can select role summary in the global filter. ' +
        "Criteria can be subject to change. For more information on program and eligibility criteria, please visit <a href='https://microsoft.sharepoint.com/teams/wwrr/SitePages/PlatinumClub-TopAttainment.aspx' target='_blank'>Top Attainment</a>.</p>";
    }
  }
  toggleBucketEstimation() {
    this.toggleEstimator = !this.toggleEstimator;
  }
}
